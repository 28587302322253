import React from "react";
import { InvoiceStatusEnum, InvoiceTitleTypeEnum } from "./common";

/**
 * 获取发票状态名
 * @param status 状态值
 */
export function getInvoiceStatusName(status) {
    switch (status) {
        case InvoiceStatusEnum.pre:
            return "预登记";
        case InvoiceStatusEnum.wait:
            return "待开票";
        case InvoiceStatusEnum.finish:
            return "已开票";
        case InvoiceStatusEnum.cancel:
            return "开票取消";
        default:
            return "";
    }
}

/**
 * 获取发票类型
 * @param invoiceType 发票类型值
 * @param type 发票主体subject 、 发票类型type， 默认为type
 */
export function getInvoiceTypeName(invoiceType, type: "subject" | "type" = "type") {
    switch (invoiceType) {
        case InvoiceTitleTypeEnum.ordinaryVat:
            return type === "subject" ? "公司" : "增值税普通发票";
        case InvoiceTitleTypeEnum.specialVat:
            return type === "subject" ? "公司" : "增值税专用发票";
        case InvoiceTitleTypeEnum.personalVat:
            return type === "subject" ? "个人" : "个人普通发票";
        default:
            return "";
    }
}
/**
 * 渲染类型
 * @param invoiceType
 * @returns
 */
export function readerInvoiceType(invoiceType: number): React.ReactNode {
    return (
        <>
            {invoiceType === InvoiceTitleTypeEnum.ordinaryVat ? <span>增值税普通发票</span> : ""}
            {invoiceType === InvoiceTitleTypeEnum.specialVat ? <span>增值税专用发票</span> : ""}
            {invoiceType === InvoiceTitleTypeEnum.personalVat ? <span>个人普通发票</span> : ""}
        </>
    );
}
/**
 * 渲染状态
 * @param invoiceStatusId
 * @param flag
 * @returns
 */
export function getStatus(invoiceStatusId: number, flag?: boolean): React.ReactNode {
    return (
        <>
            {invoiceStatusId === InvoiceStatusEnum.pre ? <span className={flag ? "color-waiting" : ""}>预登记</span> : ""}
            {invoiceStatusId === InvoiceStatusEnum.wait ? <span className={flag ? "highlight-color" : ""}>待开票</span> : ""}
            {invoiceStatusId === InvoiceStatusEnum.cancel ? <span className={flag ? "color-green" : ""}>已取消</span> : ""}
            {invoiceStatusId === InvoiceStatusEnum.finish ? <span className={flag ? "color-success" : ""}>已开票</span> : ""}
        </>
    );
}
