export enum Namespaces {
    invoice = "invoice",
    invoiceDetail = "invoiceDetail",
    invoiceTitle = "invoiceTitle",
    invoiceTitleEdit = "invoiceTitleEdit",
    invoiceSelect = "invoiceSelect",
}

/**
 * 发票类型枚举
 */
export enum InvoiceTypeEnum {
    /**
     * 全部
     */
    all = "999",
    /**
     * 未通过
     */
    unPass = "1",
    /**
     * 已通过
     */
    passed = "2",
    /**
     * 退回
     */
    back = "3",
    /**
     * 预登记
     */
    regist = "0",
    /**
     * 已开票
     */
    billSuccess = "2",
    /**
     * 待开票
     */
    billWait = "1",
    /**
     * 取消
     */
    cancelBill = "-1",
    /**
     * 已取消
     */
    cancelBillUrl = "9",
}

/**
 * 发票类型
 */
export enum CashTypeEnum {
    /**
     * 公司
     */
    company = 1,
    /**
     * 个人
     */
    person = 2,
}

/**
 * 发票状态枚举
 */
export enum InvoiceStatusEnum {
    /**
     * 预登记
     */
    pre = 0,
    /**
     * 待开票
     */
    wait = 1,
    /**
     * 开票取消
     */
    cancel = -1,
    /**
     * 已开票
     */
    finish = 2,
}

/**
 * 发票抬头类型
 */
export enum InvoiceTitleTypeEnum {
    /**
     * 增值税普通发票
     */
    ordinaryVat = 1,
    /**
     * 增值税专用发票
     */
    specialVat = 2,
    /**
     * 个人普通发票
     */
    personalVat = 3,
}

/**
 * 发票类型集合
 */
export const INVOICE_SUBJECT = [
    { label: "增值税专用发票", value: InvoiceTitleTypeEnum.specialVat },
    { label: "增值税普通发票", value: InvoiceTitleTypeEnum.ordinaryVat },
];

/**
 * 发票主体集合
 */
export const COMORPER_SUBJECT = [
    { label: "公司", value: CashTypeEnum.company },
    { label: "个人", value: CashTypeEnum.person },
];
