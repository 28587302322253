import React from "react";
import PropTypes from "prop-types";

import { getObjectProp, pictureService, requireEnsure } from "@reco-m/core";

import { getAssetsHomeUrl, resolveAssetsHomeUrl } from "../util";

import { ContextWatchdogContext } from "./ckeditor-context";
import { ContextWatchdog, EditorWatchdog, MyCustomUploadAdapterPlugin } from "./ckeditor-utils";

const ASSETS_HOME_URL = getAssetsHomeUrl(),
    {
        config: cfg,
        assets,
        isRelativePath,
    } = {
        isRelativePath: false,
        assets: [ASSETS_HOME_URL + "assets/js/ckeditor5/classic/25.0.0/ckeditor.min"],
        ...getObjectProp(client, "plugins.ckeditor", {}),
    } as any;
// assets = getObjectProp(client, "plugins.ckeditor.assets", [ASSETS_HOME_URL + "assets/js/ckeditor5/classic/25.0.0/ckeditor.min"]),
// cfg: any = getObjectProp(client, "plugins.ckeditor.config");

export { isRelativePath };

export namespace CKEditor {
    export interface IProps {
        id?: string;
        data?: string;
        disabled?: boolean;
        config?: any;
        onChange?: (e: any, editor: any) => void;
        onReady?: (editor: any) => void;
        onFocus?: (e: any, editor: any) => void;
        onBlur?: (e: any, editor: any) => void;
        onError?: (e?: any, p?: any) => void;
        onInit?: (props: any, propName: any) => void;
    }

    export interface IState {}

    class EditorWatchdogAdapter {
        private _id: string;
        private _contextWatchdog: any;
        private _creator: any;

        constructor(contextWatchdog) {
            this._contextWatchdog = contextWatchdog;
        }

        setCreator(creator) {
            this._creator = creator;
        }

        create(sourceElementOrData, config) {
            return this._contextWatchdog.add({
                sourceElementOrData,
                config,
                creator: this._creator,
                id: this._id,
                type: "editor",
            });
        }

        on(_, callback) {
            this._contextWatchdog.on("itemError", (_, { itemId, causesRestart, error }) => {
                if (itemId === this._id) {
                    callback(null, { error, causesRestart });
                }
            });
        }

        destroy() {
            this._contextWatchdog.remove(this._id);
        }

        get editor() {
            try {
                return this._contextWatchdog.getItem(this._id);
            } catch (err) {
                return null;
            }
        }
    }

    export class Component<P extends IProps = IProps, S extends IState = IState> extends React.Component<P, S> {
        static contextType = ContextWatchdogContext;

        static propTypes = {
            data: PropTypes.string,
            config: PropTypes.object,
            onChange: PropTypes.func,
            onReady: PropTypes.func,
            onFocus: PropTypes.func,
            onBlur: PropTypes.func,
            onError: PropTypes.func,
            disabled: PropTypes.bool,
            onInit: (props: any, propName: any) => {
                if (props[propName]) {
                    return new Error('The "onInit" property is not supported anymore by the CKEditor component. Use the "onReady" property instead.');
                }
            },
        };

        static defaultProps = {
            config: {},
            onError: (error: any, details: any) => console.error(error, details),
        };

        private domContainer = React.createRef<any>();
        private watchdog: any = null;

        id: string;

        get editor() {
            if (!this.watchdog) {
                return null;
            }

            return this.watchdog.editor;
        }

        constructor(props: P, context: any) {
            super(props, context);

            this.id = props.id || pictureService.guid("ck");
        }

        shouldComponentUpdate(nextProps: P) {
            if (!this.editor) {
                return false;
            }

            if (nextProps.id !== this.props.id) {
                this._destroyEditor();

                return true;
            }

            if (this._shouldUpdateEditor(nextProps)) {
                this.editor.setData(nextProps.data);
            }

            if ("disabled" in nextProps) {
                this.editor.isReadOnly = nextProps.disabled;
            }

            return false;
        }

        componentDidMount() {
            this._initializeEditor();
        }

        componentDidUpdate() {
            this._initializeEditor();
        }

        componentWillUnmount() {
            this._destroyEditor();
        }

        render() {
            return <div ref={this.domContainer}></div>;
        }

        private _initializeEditor() {
            if (this.context instanceof ContextWatchdog) {
                this.watchdog = new EditorWatchdogAdapter(this.context);
            } else {
                this.watchdog = new EditorWatchdog(this.editor);
            }

            this.watchdog.setCreator((el, config) => this._createEditor(el, config));

            this.watchdog.on("error", (_, { error, causesRestart }) => {
                this.props.onError!(error, { phase: "runtime", willEditorRestart: causesRestart });
            });

            this.watchdog.create(this.domContainer.current, this._getConfig()).catch((error) =>
                this.props.onError!(error, {
                    phase: "initialization",
                    willEditorRestart: false,
                })
            );
        }

        private async _createEditor(element, config) {
            await requireEnsure(assets.map(resolveAssetsHomeUrl));

            return await window["ClassicEditor"].create(element, config).then((editor) => {
                if ("disabled" in this.props) {
                    editor.isReadOnly = this.props.disabled;
                }

                const modelDocument = editor.model.document;
                const viewDocument = editor.editing.view.document;

                modelDocument.on("change:data", (event) => {
                    if (this.props.onChange) {
                        this.props.onChange(event, editor);
                    }
                });

                viewDocument.on("focus", (event) => {
                    if (this.props.onFocus) {
                        this.props.onFocus(event, editor);
                    }
                });

                viewDocument.on("blur", (event) => {
                    if (this.props.onBlur) {
                        this.props.onBlur(event, editor);
                    }
                });

                setTimeout(() => {
                    if (this.props.onReady) {
                        this.props.onReady(this.editor);
                    }
                });

                return editor;
            });
        }

        private _destroyEditor() {
            this.watchdog.destroy();
            this.watchdog = null;
        }

        private _shouldUpdateEditor(nextProps) {
            if (this.props.data === nextProps.data) {
                return false;
            }

            if (this.editor.getData() === nextProps.data) {
                return false;
            }

            return true;
        }

        private _getConfig() {
            if (this.props.data && this.props.config?.initialData) {
                console.warn(
                    "Editor data should be provided either using `config.initialData` or `data` properties. " +
                        "The config property is over the data value and the first one will be used when specified both."
                );
            }

            return {
                toolbar: {
                    items: [
                        "heading",
                        "|",
                        "fontSize",
                        "fontFamily",
                        "fontColor",
                        "fontBackgroundColor",
                        "|",
                        "bold",
                        "italic",
                        "underline",
                        "strikethrough",
                        "horizontalLine",
                        "|",
                        "alignment",
                        "|",
                        "numberedList",
                        "bulletedList",
                        "|",
                        "outdent",
                        "indent",
                        "|",
                        "specialCharacters",
                        "link",
                        "imageInsert",
                        "blockQuote",
                        "insertTable",
                        "mediaEmbed",
                        "undo",
                        "redo",
                    ],
                },
                language: "zh-cn",
                image: {
                    styles: ["full", "alignLeft", "alignRight"],
                    toolbar: ["imageStyle:alignLeft", "imageStyle:full", "imageStyle:alignRight", "|", "imageTextAlternative", "linkImage"],
                },
                table: {
                    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells", "tableCellProperties", "tableProperties"],
                },
                mediaEmbed: {
                    extraProviders: [
                        {
                            name: "ckeditor",
                            url: /\.mp4\b\??(?:width=(\w+%?)(?:&height=(\w+%?)&?)?)?/,
                            html: (match: any) => {
                                const [, width, height] = match;

                                return `<video ${width ? `width=${width}` : ""} ${height ? `height=${height}` : ""} controls autoplay loop>
                                <source src="${match.input}" type="video/mp4">
                                </video>`;
                            },
                        },
                    ],
                },
                ...cfg,
                extraPlugins: [MyCustomUploadAdapterPlugin(this.id, pictureService)],
                ...this.props.config,
                initialData: this.props.config?.initialData || this.props.data || "",
            };
        }
    }
}
