import {
    CommonSearchParamInUrlEnum,
    IParkBindTableNameEnum,
    // IS_OPEN_BUSINESS_BILL,
    // IS_OPEN_ENTERPRISE_HOME,
    // IS_OPEN_RECRUIT,
    WorkOrderSearchParamInUrlEnum,
} from "@reco-w/common-common";
// import { MemberManagerEnum } from "@reco-w/member-models";
import { SearchParamInUrlEnum as FavouriteSearchParamInUrlEnum } from "@reco-w/favorites-models";
// import { SearchParamInUrlEnum as MemberSearchParamInUrlEnum } from "@reco-w/member-models";
import { MySettingTabEnum } from "@reco-w/my-models";

/**
 * 与我相关 的目录
 * @returns
 */
function getMyMenuRelatedToMe() {
    return [
        {
            key: "/my/myserve",
            url: `/my/myserve?${WorkOrderSearchParamInUrlEnum.listcode}=QB`,
            icon: "iconjilu",
            title: "服务记录",
        },
        // {
        //     key: "/my/order/0",
        //     url: `/my/order/0`,
        //     icon: "iconziyuan",
        //     title: "资源订单",
        // },
        // {
        //     key: "/my/myDeclare",
        //     url: `/my/myDeclare`,
        //     icon: "iconshenbao1",
        //     title: "我的政策申报",
        //     special: "declareMode",
        // },
        // {
        //     key: "/my/mySubscription",
        //     url: `/my/mySubscription`,
        //     icon: "icondingyue",
        //     title: "我的政策订阅",
        // },
        {
            key: "/my/myactivity",
            url: `/my/myactivity`,
            icon: "iconhuodong",
            title: "我的活动",
        },
        // {
        //     key: "/my/mysurvey",
        //     url: `/my/mysurvey`,
        //     icon: "iconwenjuan1",
        //     title: "我的问卷",
        // },
        {
            key: "/my/myfavorte",
            url: `/my/myfavorte?${FavouriteSearchParamInUrlEnum.chosenFavourite}=${IParkBindTableNameEnum.activity}`,
            icon: "iconshoucang",
            title: "我的收藏",
        },
        // {
        //     key: "/my/mycircle",
        //     url: `/my/mycircle`,
        //     icon: "iconduihua",
        //     title: "我的话题",
        // },
        // {
        //     key: "/my/datareport1",
        //     url: `/my/datareport/1`,
        //     icon: "iconziyuan",
        //     title: "个人信息上报",
        // }
    ];
}

/**
 * 权益中心 的目录
 * @returns
 */
// function getMyMenuRightCenter() {
//     return [
//         {
//             key: "/my/mine",
//             url: `/my/mine?${CommonSearchParamInUrlEnum.status}=1`,
//             icon: "iconcoupon",
//             title: "优惠券",
//         },
//         {
//             key: "/my/myintegral",
//             url: `/my/myintegral?${MemberSearchParamInUrlEnum.ruleType}=${IntergralRuleTypeEnum.earn}&${MemberSearchParamInUrlEnum.intergralType}=${CurrentIntergralTypeEnum.person}&${CommonSearchParamInUrlEnum.titleTab}=1`,
//             icon: "iconjifen",
//             title: "积分管理",
//         },
//         {
//             key: "/my/myinvoice",
//             url: `/my/invoice?${CommonSearchParamInUrlEnum.status}=${InvoiceTypeEnum.all}`,
//             icon: "iconfapiao",
//             title: "我的发票",
//         }
//     ];
// }

// const openRecruit = IS_OPEN_RECRUIT,
//     openEnterpriseHome = IS_OPEN_ENTERPRISE_HOME,
//     openBusinessBill = IS_OPEN_BUSINESS_BILL;

/**
 * 企业相关 的目录
 * @returns
 */
function getMyMenuRelatedToBusiness() {
    let result: any[] = [];

    // if (openEnterpriseHome) {
    //     result.push({
    //         key: "/my/myEnterprise",
    //         url: "/my/myEnterprise",
    //         icon: "iconshangchuan",
    //         isAdminCertify: true,
    //         title: "主页发布",
    //     });
    // }

    // if (openRecruit) {
    //     result.push({
    //         key: "/my/myRecruit",
    //         url: "/my/myRecruit",
    //         icon: "icontijiao",
    //         isAdminCertify: true,
    //         title: "职位发布",
    //     });
    // }

    // result.push({
    //     key: "/my/myperson",
    //     url: `/my/myperson?${CommonSearchParamInUrlEnum.titleTab}=${MemberManagerEnum.manage}`,
    //     icon: "iconguanliyuan",
    //     isAdminCertify: true,
    //     title: "员工管理",
    // });

    // if (openBusinessBill) {
    //     result.push({
    //         key: "/my/myBusinessBill",
    //         url: `/my/myBusinessBill`,
    //         icon: "iconjilu1",
    //         isAdminCertify: true,
    //         title: "企业账单",
    //     });
    // }

    result = [
        ...result,
        {
            key: "/my/myproject",
            icon: "iconruzhu",
            title: "入驻项目",
            special: "checkOrder",
        },
        {
            key: "/my/mymarket",
            icon: "iconjigou1",
            title: "服务机构",
            isAdminCertify: true,
            special: "institution",
        },
        // {
        //     key: "/my/datareport2",
        //     url: `/my/datareport/2`,
        //     icon: "iconjilu",
        //     isAdminCertify: true,
        //     title: "企业数据上报",
        // }
    ];

    return result;
}

/**
 * 我的 目录
 * @returns
 */
export function getMyMenu() {
    return [
        {
            key: "/my",
            url: `/my`,
            icon: "iconhome-g",
            title: "我的主页",
        },
        {
            key: "/my/mynotice",
            url: `/my/mynotice`,
            icon: "iconxiaoxi",
            title: "消息中心",
            badgeMap: "noticeCount",
        },
        {
            key: "/my/relatedToMe",
            icon: "iconcorrelation",
            title: "与我相关",
            children: getMyMenuRelatedToMe(),
        },
        // {
        //     key: "/my/rightCenter",
        //     icon: "iconrights",
        //     title: "权益中心",
        //     children: getMyMenuRightCenter(),
        // },
        {
            key: "/my/relatedToBusiness",
            icon: "iconfirm",
            title: "企业相关",
            children: getMyMenuRelatedToBusiness(),
        },
        {
            key: "/my/mysetting",
            url: `/my/mysetting?${CommonSearchParamInUrlEnum.titleTab}=${MySettingTabEnum.user}`,
            icon: "iconshezhi",
            title: "账号设置",
        },
    ];
}
