import React from "react";
import { Button, Modal } from "antd";

import { template } from "@reco-m/core";
import { ViewComponent, HtmlContent } from "@reco-w/core-ui";
import { Namespaces, footerModel } from "@reco-w/layout-models";

export namespace UserAgreementModel {
    export interface IProps<S extends IState = IState> extends ViewComponent.IProps<S> {
        /**
         * 是否显示
         */
        isVisible: boolean;
        /**
         * 关闭方法
         */
        onClose: () => void;
        /**
         * 标题，默认为“用户协议”
         */
        title?: string;
        /**
         * 配置数据中的对应字段，默认为“serviceAgreement”
         */
        configMap?: string;
    }
    export interface IState extends ViewComponent.IState, footerModel.StateType { }

    export class Component<P extends IProps = IProps, S extends IState = IState> extends ViewComponent.Base<P, S> {
        namespace = Namespaces.footer;

        renderUserAgreementModel() {
            const { isVisible, onClose, state, title = "用户协议", configMap = "serviceAgreement" } = this.props,
                appConfig = state && state.appConfig;
            return (
                <Modal
                    title={title}
                    visible={isVisible}
                    width="60%"
                    wrapClassName="home-modal"
                    style={{ height: "95%" }}
                    centered
                    onCancel={() => onClose && onClose()}
                    footer={[
                        <Button key="cancel" onClick={() => onClose && onClose()}>
                            确定
                        </Button>,
                    ]}
                >
                    <div className="content background-white" style={{ lineHeight: 1.5 }}>
                        <HtmlContent.Component html={appConfig && appConfig[configMap]}></HtmlContent.Component>
                    </div>
                </Modal>
            );
        }

        render() {
            return this.renderUserAgreementModel();
        }
    }

    export const Page = template(Component, (state) => state[Namespaces.footer]);
}
