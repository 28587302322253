import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreState, CoreReducers, getCurrentParkId } from "@reco-m/core";
import { app, mtaH5Click, statisticsEvent } from "@reco-w/core-ui";

import { IParkBindTableNameEnum } from "@reco-w/common-common";
import { authService } from "@reco-w/auth-service";
import { cashInvoiceService } from "@reco-w/invoice-service";

import { CashTypeEnum, InvoiceTitleTypeEnum, Namespaces } from "./common";

export namespace invoiceTitleEditModel {
    export const namespace = Namespaces.invoiceTitleEdit;

    export const state: any = freeze(
        {
            ...CoreState,
            detail: {},
            title: null,
            taxId: null,
            registerAddress: null,
            registerTel: null,
            bankName: null,
            bankAccount: "",
            invoiceType: InvoiceTitleTypeEnum.specialVat,
            comOrPerType: CashTypeEnum.company,
            isLoading: false,
        },
        !0
    );

    export type StateType = typeof state;
    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,
        *initPage({ error, id, callback }, { call, put }) {
            try {
                yield put({ type: "initState" });
                if (id && id !== "0") {
                    yield put({ type: "getInvoice", id, callback });
                }
            } catch (e) {
                yield call(error, "" + e.errmsg);
            }
        },

        *getInvoice({ error, id, callback }, { call, put }) {
            try {
                yield put({ type: "showLoading" });

                const detail = yield call(cashInvoiceService.getInvoiceDetail, id);

                yield put({
                    type: "input",
                    data: {
                        ...detail,
                        invoiceDetail: detail,
                        comOrPerType: detail.invoiceType === InvoiceTitleTypeEnum.personalVat ? CashTypeEnum.person : CashTypeEnum.company,
                    },
                });
                yield call(callback!, detail);
            } catch (e) {
                yield call(error, "" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
        *submitInvoiceTitle({ error, id, callback }, { select, call, put }) {
            try {
                yield put({ type: "input", data: { isSubmiting: true } });
                const state = yield select((state) => state[Namespaces.invoiceTitleEdit]);
                const user = yield call(authService.getCurrentUser);
                const userId = user.currentUser && user.currentUser.id;

                let params;
                const commonParams = {
                    bindTableName: IParkBindTableNameEnum.account,
                    bindTableId: userId,
                    title: state!.title,
                    invoiceType: state!.invoiceType,
                    invoiceTypeId: state!.invoiceType,
                    parkId: getCurrentParkId(),
                };
                if (+state!.invoiceType === InvoiceTitleTypeEnum.specialVat) {
                    params = {
                        ...commonParams,
                        taxId: state!.taxId,
                        registerAddress: state!.registerAddress,
                        registerTel: state!.registerTel,
                        bankName: state!.bankName,
                        bankAccount: state!.bankAccount,
                    };
                } else if (+state!.invoiceType === InvoiceTitleTypeEnum.ordinaryVat) {
                    params = {
                        ...commonParams,
                        taxId: state!.taxId,
                    };
                } else if (+state!.invoiceType === InvoiceTitleTypeEnum.personalVat) {
                    params = {
                        ...commonParams,
                    };
                }

                let data;
                if (id && id !== "0") {
                    data = yield call(cashInvoiceService.modifyITitle, id, {
                        ...params,
                        id,
                    });
                    mtaH5Click(statisticsEvent.myInvoiceTitleEdit);
                } else {
                    data = yield call(cashInvoiceService.post, {
                        ...params,
                        bindTableId: userId,
                    });
                    mtaH5Click(statisticsEvent.myInvoiceTitleAdd);
                }

                yield call(callback!, data);
            } catch (e) {
                yield call(error, "" + e.errmsg);
            } finally {
                yield put({ type: "input", data: { isSubmiting: false } });
            }
        },
    };
}

app.model(invoiceTitleEditModel);
