import { MymenuBitech } from "./menu";

import { registerMyMenuModel } from "@reco-w/my-menumodels";

export { MymenuBitech };
export * from "./common";

const pageLayout = client.pageLayout || (client.pageLayout = {} as any);

export function registerPageLayoutMyMenu({ pageMyMenu, pageMyMenuModel }: any = {}) {
    registerMyMenuModel(pageMyMenuModel);

    (pageMyMenu && (pageLayout.pageMyMenu = pageMyMenu)) || pageLayout.pageMyMenu || (pageLayout.pageMyMenu = MymenuBitech.Page);
}
