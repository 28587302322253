import { isNil, isObject, isString } from 'lodash';

import { getCurrentParkId, getCurrentUnitId } from '../utils';

import { RequestOptionsArgs } from './type';

import { urlEncodeParams, resolveUrl, attachXRequestIdToHeader } from './utils';
import { getCurrentToken } from './token';

export class HttpRequest {
    readonly responseType: 'arraybuffer' | 'blob' | 'json' | 'text' = 'json';

    readonly options?: RequestOptionsArgs;

    constructor(public url: string, options: RequestOptionsArgs = {}) {
        this.responseType = options.responseType || this.responseType;
        this.options = options;
    }

    get request() {
        const { headers: rawHeaders, body: rawBody, params } = this.options!,
            url = resolveUrl(this.url, `${this.getUrlEncodeParams(params)}`),
            headers = this.getHeaders(rawHeaders),
            body = rawBody === void 0 ? void 0 : rawBody instanceof FormData ? (delete headers['Content-Type'], rawBody) : JSON.stringify(rawBody);

        this.attachXRequestIdToHeader(headers, url, isString(body) ? new TextEncoder().encode(body).length : null);

        return new Request(url, {
            ...this.options,
            body,
            headers,
        } as any);
    }

    protected getUrlEncodeParams(params: any) {
        return isNil(params) ? '' : isObject(params) ? urlEncodeParams(params) : params;
    }

    protected getHeaders(headers: any) {
        headers = this.attachAuthorization({
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'terminal-type': 'h5',
            ...headers,
        });

        this.attachUnitId(headers);
        this.attachParkId(headers);

        return headers;
    }

    protected attachXRequestIdToHeader(headers: any, url: string, contentLength: number | null = null) {
        return attachXRequestIdToHeader(headers, url, contentLength);
    }

    protected attachAuthorization(headers: any) {
        const { allowAnonymous } = this.options!;

        if (!allowAnonymous && !headers.Authorization) {
            const authorization = getCurrentToken();

            if (authorization) {
                headers.Authorization = authorization;
            }
        }

        return headers;
    }

    protected attachUnitId(headers: any) {
        const { unitId = getCurrentUnitId() } = this.options!;

        delete headers.unitId;

        headers.ignoreUnitId || (unitId && (headers.unitId = unitId));

        delete headers.ignoreUnitId;
    }

    protected attachParkId(headers: any) {
        const { parkId = getCurrentParkId() } = this.options!;

        delete headers.parkId;

        headers.ignoreParkId || (parkId && (headers.parkId = parkId));

        delete headers.ignoreParkId;
    }

    clone(): HttpRequest {
        return new HttpRequest(this.url, this.options);
    }
}
