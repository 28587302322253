import React from "react";
import { ProductChargeModeEnum } from "./common";

/**
 * 获取服务产品付费单位
 * @param item
 */
export function getServiceProductPriceUnit(item) {
    if (!item) {
        return "--";
    }

    return item.chargeModeValue === ProductChargeModeEnum.free ? (
        <span>免费</span>
    ) : item.chargeModeValue === ProductChargeModeEnum.discuss ? (
        <span>面议</span>
    ) : (
        <span>{"￥" + ((item.chargeMinPrice || "") + "-" + (item.chargeMaxPrice || "") + (item.chargeUnit || ""))}</span>
    );
}
