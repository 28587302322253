import { getLocalToken, refreshSystemToken, HttpClient, customEvent, logout } from '@reco-m/core';

export const systemTokenPromise = (HttpClient.systemTokenPromise = new Promise<void>((resolve) => {
    addEventListener('storage', (e) => {
        if (e.key === 'sso::get::token' || e.key === 'sso::logged') {
            const token = getLocalToken(),
                now = `${e.newValue}`;

            if (token && now.startsWith('now:')) {
                localStorage.setItem('sso::get::token', token);
            } else if (now.startsWith('Bearer ')) {
                refreshSystemToken({ token_str: now, access_token: '', refresh_token: '' }, false);

                localStorage.setItem('sso::get::token', `reset:${Date.now()}`);

                location.reload();
            }
        } else if (e.key === 'sso::logout') {
            logout();

            location.reload();
        }
    });

    customEvent.on('logout', () => {
        localStorage.setItem('sso::logout', `now:${Date.now()}`);
    });

    customEvent.on('logged', () => {
        localStorage.setItem('sso::logged', getLocalToken()!);
    });

    if (!getLocalToken()) {
        localStorage.setItem('sso::get::token', `now:${Date.now()}`);

        setTimeout(resolve, 90);
    } else {
        resolve();
    }
}));
