import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreState, CoreReducers, getCurrentParkId } from "@reco-m/core";
import { app, getSearchUrl } from "@reco-w/core-ui";

import { CommonSearchParamInUrlEnum, IParkBindTableNameEnum } from "@reco-w/common-common";
import { authService } from "@reco-w/auth-service";
import { cashInvoiceService } from "@reco-w/invoice-service";

import { Namespaces, InvoiceTypeEnum } from "./common";

export namespace invoiceModel {
    export const namespace = Namespaces.invoice;

    export const state: any = freeze(
        {
            ...CoreState,
            showloading: true,
            currentPage: 1,
            totalItems: 1,
            pageSize: 8,
            status: InvoiceTypeEnum.all,
        },
        !0
    );

    export type StateType = typeof state;
    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ error, props }, { call, put }) {
            try {
                yield put({ type: "initState" });
                yield put({ type: "getInvoicesListByUrl", props });
            } catch (e) {
                yield call(error, "" + e.errmsg);
            }
        },
        /**
         * 处理Url，获取列表
         * @param props
         */
        *getInvoicesListByUrl({ message, props }, { call, put }) {
            try {
                const params = getSearchUrl([CommonSearchParamInUrlEnum.pageIndex, CommonSearchParamInUrlEnum.pageSize, CommonSearchParamInUrlEnum.status], props);
                yield put({
                    type: "input",
                    data: {
                        currentPage: Number(params[CommonSearchParamInUrlEnum.pageIndex]) || 1,
                        pageSize: Number(params[CommonSearchParamInUrlEnum.pageSize]) || 8,
                        status: params[CommonSearchParamInUrlEnum.status],
                    },
                });

                let status = params[CommonSearchParamInUrlEnum.status];
                switch (params[CommonSearchParamInUrlEnum.status]) {
                    case InvoiceTypeEnum.all:
                        status = null;
                        break;
                    case InvoiceTypeEnum.cancelBillUrl:
                        status = "-1";
                        break;
                    default:
                        break;
                }

                const data = {
                    parkId: getCurrentParkId(),
                    pageIndex: params[CommonSearchParamInUrlEnum.pageIndex] || 1,
                    pageSize: params[CommonSearchParamInUrlEnum.pageSize] || 8,
                    invoiceStatus: status,
                };

                yield put({
                    type: "getInvoices",
                    params: data,
                });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },
        /**
         * 获取当前用户发票
         */
        *getInvoices({ message, params }, { call, put }) {
            try {
                yield put({ type: "showLoading" });

                const user = yield call(authService.getCurrentUser);
                const userId = user.currentUser && user.currentUser.id;

                let result = yield call(cashInvoiceService.getPaged, {
                    inputerId: userId,
                    bindTableName: IParkBindTableNameEnum.order,
                    ...params,
                });

                yield put({ type: "input", data: { ...result } });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
    };
}

app.model(invoiceModel);
