const ddkit = window["dd"];
export const browser = {
    versions: (function () {
        const u = navigator.userAgent;

        return {
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
            android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, // android终端或者uc浏览器
            iPhone: u.indexOf("iPhone") > -1 || u.indexOf("Mac") > -1, // 是否为iPhone或者QQHD浏览器
            iPad: u.indexOf("iPad") > -1, // 是否iPad
            weChat: u.indexOf("MicroMessenger") > -1,
            weChatMini: u.indexOf("miniProgram") != -1, // 小程序
            weChatep: u.includes('wxwork'), // 微信公众号
            dingding: ddkit && ddkit.env.platform !== "notInDingTalk", // 钉钉
            gdDingding: ddkit && ddkit?.authConfig // 专有钉钉
        };
    })(),
};
export function getAndroidVersion(ua?) {
    ua = (ua || navigator.userAgent).toLowerCase(); 
    let match = ua.match(/android\s([0-9\.]*)/i);
    return match ? match[1] : undefined;
};

// 钉钉设置页面标题处理
export function setNavTitle(title: string, nextProps?) {
    let locationChanged = nextProps ? nextProps.location !== this.props.location : null;
    // 没传nextProps或者页面是从子路由返回时设置title
    if ((locationChanged && nextProps.location!.pathname.length < this.props.location!.pathname!.length) || !nextProps) {
        if (browser.versions.dingding || browser.versions.gdDingding) {
            ddkit?.biz.navigation.setTitle({
                title: title,
                onSuccess: function (result) {
                    console.log("setTitleOnSuccess", result);

                },
                onFail: function (err) {
                    console.log("setTitleOnFail", err);

                }
            });
        } else {
            !client.showheader && (document.title = title);
        }
    }
}


