import { EffectsMapObject, routerRedux } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { getLocalStorage, setLocalStorage } from "@reco-m/core";
import { app, getParkCode } from "@reco-w/core-ui";

import { Namespaces as commonNamespaces, CertifyStatusEnum } from "@reco-w/common-models";
import { parkService } from "@reco-w/home-service";
import { MemberRoleNameBuiltInEnum } from "@reco-w/member-models";

import { Namespaces, headerModel } from "@reco-w/layout-models";

export namespace headerNewModel {
    export const namespace = Namespaces.header;
    export const state: any = freeze(
        {
            ...headerModel.state,
        },
        !0
    );

    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...headerModel.reducers,
        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...headerModel.effects,
        /**是否入驻企业信息 */
        *getCertifyAndInstitution({ message }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.configCommon}/getMemberConfig` });
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCertify` });
                yield yield put({ type: `${commonNamespaces.workorderCommon}/getInstitution`, isRefresh: true });

                const memberCommonState: any = yield select((state) => state[commonNamespaces.memberCommon]);
                const workorderCommonState: any = yield select((state) => state[commonNamespaces.workorderCommon]);
                const member = memberCommonState.member;
                const institution = workorderCommonState.institution;
                let isFoot = false;
                if (
                    typeof institution?.serviceInstitutionBasicFormVM?.status === "undefined" &&
                    member?.status === CertifyStatusEnum.allow &&
                    member.companyUserTypeName === MemberRoleNameBuiltInEnum.manager
                ) {
                    isFoot = true;
                }

                yield put({ type: "input", data: { isFoot } });
            } catch (e) {
                yield call(message!.error, "getCertify：" + e.errmsg);
            }
        },

        /**
         * 获取园区
         * @param { callback, judgeParkCode, message }
         * @param { call, put }
         */
        *getPark({ callback, judgeParkCode, message }, { call, put }) {
            try {
                const { items } = yield call(parkService.getPaged);
                let parkNames: any[] = [];
                items &&
                    items.length > 0 &&
                    items.forEach((item) => {
                        parkNames.push({ label: item.parkName, value: item.id, code: item.parkCode });
                    });

                // 判断当前园区和缓存中的园区是否一致
                if (judgeParkCode) {
                    const selectedPark = parkNames && parkNames.length > 0 && parkNames.find((x) => x.code === judgeParkCode);
                    console.log('selectedParkselectedParkselectedPark', selectedPark, judgeParkCode);

                    if (!selectedPark) {
                        // 如果当前园区不存在，则把第一条数据设置为当前园区
                        setLocalStorage("parkId", parkNames[0].value);
                        setLocalStorage("parkName", parkNames[0].label);
                        setLocalStorage("parkCode", parkNames[0].code);
                        yield put(routerRedux.replace(`/${parkNames[0].code}`));
                        yield put({ type: "input", data: { radioSelect: parkNames[0].value, parkName: parkNames[0].label, i: Math.random() } });
                    } else {
                        const isRefresh = getParkCode() && selectedPark && selectedPark.value.toString() !== getLocalStorage("parkId");
                        if (isRefresh || !getLocalStorage("parkId")) {
                            setLocalStorage("parkId", selectedPark?.value);
                            setLocalStorage("parkName", selectedPark?.label);
                            setLocalStorage("parkCode", selectedPark?.code);
                            // isRefresh && window.location.reload();
                        }
                    }
                }
                
                /** 单元区，默认第一个  */
                setLocalStorage("parkId", parkNames[0].value);
                setLocalStorage("parkName", parkNames[0].label);
                setLocalStorage("parkCode", parkNames[0].code);

                // 不存在缓存时
                if (parkNames && parkNames.length > 0 && !getLocalStorage("parkId") && !getLocalStorage("parkName")) {
                    setLocalStorage("parkId", parkNames[0].value);
                    setLocalStorage("parkName", parkNames[0].label);
                    setLocalStorage("parkCode", parkNames[0].code);
                    yield put({ type: "input", data: { radioSelect: parkNames[0].value, parkName: parkNames[0].label, i: Math.random() } });
                }

                // 不存在parkcode时
                if (getLocalStorage("parkCode")) {
                    if (getLocalStorage("parkId")) {
                        const park = parkNames.find((x) => x.value.toString() === getLocalStorage("parkId"));
                        park && setLocalStorage("parkCode", park.code);
                    } else {
                        setLocalStorage("parkCode", client["parkCode"]);
                    }
                }

                yield put({ type: "input", data: { parkNames } });

                if (callback) yield call(callback);
            } catch (e) {
                yield call(message!.error, "getPark" + e.errmsg);
            }
        },
    };
}

export function registerPageHeaderModel(model?: any) {
    app.replaceModel(model || headerNewModel);
}
