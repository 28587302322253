import React from "react";
import { message } from "antd";

import { BaseAttach } from "@reco-m/core";

import { getDefaultProps } from "./util";
import { UploadWrap } from "./upload.wrap";

export namespace Attach {
    export interface IProps extends BaseAttach.IProps {
        customUpload?: boolean;
        uploadText?: string | React.ReactNode;
        prefix?: React.ReactNode;
        suffix?: React.ReactNode;
    }

    export interface IState extends BaseAttach.IState {}

    export class Component<P extends IProps = IProps, S extends IState = IState> extends BaseAttach.Component<P, S> {
        static defaultProps = getDefaultProps();

        protected onShowErrorMessage(msg: string): void {
            message.error(msg);
        }

        render(): React.ReactNode {
            return <UploadWrap.Component {...this.props as any} />;
        }
    }
}
