import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreState, CoreEffects, CoreReducers, getLocalStorage, getCurrentParkId , getCurrentUnitId} from "@reco-m/core";
import { app, distinct, getSearchUrl, transformArrFromMultiToSingle } from "@reco-w/core-ui";

import { CertifyStatusEnum, Namespaces as commonNamespaces } from "@reco-w/common-models";
import { policyCalculatorService, policySubscribeService } from "@reco-w/policy-service";
import { CommonSearchParamInUrlEnum, SourceEnum } from "@reco-w/common-common";
import { datatagGroupService, datatagService } from "@reco-w/datatag-service";
import { Namespaces, PolicyCalculatorTabIndexEnum, PolicyTagDataTypeEnum } from "./common";
import { policyTagJsonToList } from "./utils";

export namespace newpolicyCalculatorModel {
    export const namespace = Namespaces.newpolicyCalculator;

    export const DEFAULT_SHOW_NUMBER = 4;

    export const state: any = freeze({
        ...CoreState,
        showloading: true,
    });
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };
    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message, props }, { call, put, select }) {
            try {
                yield put({ type: "initState", message });
                yield yield put({ type: "getCertify", message });

                const state = yield select((state) => state[Namespaces.newpolicyCalculator]),
                    isShowCalculator = state!.isShowCalculator;
                if (isShowCalculator) {
                    yield put({ type: "getDataByUrl", props, message });
                    yield put({ type: "getSubscription", message });
                }
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },

        /**
         * 根据路由获取数据
         * @param { message, props }
         * @param { call, put }
         */
        *getDataByUrl({ message, props }, { call, put, select }) {
            try {
                const params = getSearchUrl([CommonSearchParamInUrlEnum.childTabIndex, CommonSearchParamInUrlEnum.pageIndex], props);

                const tabIndex = params[CommonSearchParamInUrlEnum.childTabIndex] || PolicyCalculatorTabIndexEnum.calculator;
                const pageIndex = Number(params[CommonSearchParamInUrlEnum.pageIndex] || 1);

                const state = yield select((state) => state[Namespaces.newpolicyCalculator]),
                    oldTabIndex = state!.tabIndex,
                    oldPageIndex = state!.pageIndex,
                    isShowCalculator = state!.isShowCalculator;

                if (oldTabIndex !== tabIndex) {
                    // 如果tabIndex变化
                    yield yield put({ type: "getAllTagListItems", message });
                    yield yield put({ type: "getBasicPolicyTagList", message });
                    yield yield put({ type: "getPolicyTagList", message });
                    yield yield put({ type: "handlePolicyTagList", message, pageIndex });
                    if (isShowCalculator) yield yield put({ type: "getLastCalculateTag", message });
                    if (tabIndex === PolicyCalculatorTabIndexEnum.result) {
                        yield put({ type: "handleSelectedTagList", message, isGenerateOperateLog: false });
                    }
                } else if (pageIndex !== oldPageIndex) {
                    // 如果pageIndex变化
                    yield put({ type: "handlePolicyTagList", message, pageIndex });
                }

                yield put({ type: "input", data: { tabIndex, currentPage: pageIndex } });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },

        /**
         * 获取会员认证信息
         * @param { message }
         * @param { call, put, select }
         */
        *getCertify({ message }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCertify`, message, isRefreshUser: true });
                const memberCommonState: any = yield select((state) => state[commonNamespaces.memberCommon]),
                    user = memberCommonState.user,
                    certifyDetail = memberCommonState.member;

                const isShowCalculator = CertifyStatusEnum.allow === certifyDetail?.status;

                yield put({ type: "input", data: { isShowCalculator, certifyDetail, user } });

                if (!isShowCalculator) {
                    yield put({ type: "hideLoading" });
                }
            } catch (e) {
                yield put({ type: "hideLoading" });
                yield call(message?.error, "" + e.errmsg);
            }
        },

        /**
         * 获取当前用户政策订阅
         * @param { message }
         * @param { call, put }
         */
        *getSubscription({ message }, { call, put }) {
            try {
                const result = yield call(policySubscribeService.getByUser);

                yield put({ type: "input", data: { subscription: result } });
            } catch (e) {
                yield call(message!.error, e.errmsg);
            }
        },

        /**
         * 获取用户最后一次操作的标签记录
         * @param { message }
         * @param { call, put }
         */
        *getLastCalculateTag({ message }, { call, put, select }) {
            try {
                const result = yield call(policyCalculatorService.getLastCalculateTag, getLocalStorage("companyId"));

                let state = yield select((state) => state[Namespaces.newpolicyCalculator]),
                    allTagListItems = state!.allTagListItems || [],
                    basicTagList = state!.basicTagList || [],
                    tagList = state!.tagList || [];

                const originalSelectedTagObj = JSON.parse(result || "{}");
                const selectedTagObj: any = {};

                // 过滤已经不使用的标签
                [...basicTagList, ...tagList].forEach((item) => {
                    if (item.dataTypeValue === PolicyTagDataTypeEnum.string) {
                        if (originalSelectedTagObj[item.id + "Select"]) {
                            const tagItemList = allTagListItems.filter((x) => x.tagClassId === item.id);
                            selectedTagObj[item.id + "Select"] = Array.isArray(originalSelectedTagObj[item.id + "Select"])
                                ? tagItemList.filter((x) => originalSelectedTagObj[item.id + "Select"].some((a) => a.id === x.id))
                                : tagItemList.find((x) => originalSelectedTagObj[item.id + "Select"].id === x.id);
                        }
                    } else {
                        selectedTagObj[item.id + "Select"] = originalSelectedTagObj[item.id + "Select"];
                    }
                });

                yield put({ type: "input", data: { selectedTagObj } });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取政策匹配默认标签
         * @param { message }
         * @param { call, put }
         */
        *getBasicPolicyTagList({ message }, { call, put, select }) {
            try {
                const state = yield select((state) => state[Namespaces.newpolicyCalculator]),
                    originalBasicTagList = state!.originalBasicTagList;
                if (originalBasicTagList) {
                    return;
                }

                const result = yield call(policyCalculatorService.getPolicyMatchDefaultTagList);

                yield put({ type: "getPolicyTagListItems", message, tagList: result });
                yield put({ type: "input", data: { originalBasicTagList: result || [] } });
            } catch (e) {
                yield put({ type: "hideLoading" });
                yield call(message?.error, "" + e.errmsg);
            }
        },

        /**
         * 获取政策标签
         * @param { message }
         * @param { call, put }
         */
        *getPolicyTagList({ message }, { call, put, select }) {
            try {
                let state = yield select((state) => state[Namespaces.newpolicyCalculator]),
                    tagList = state!.tagList,
                    originalBasicTagList = state!.originalBasicTagList;

                if (tagList) {
                    return;
                }

                if (!originalBasicTagList) {
                    yield put({ type: "getBasicPolicyTagList", message });
                    state = yield select((state) => state[Namespaces.newpolicyCalculator]);
                    originalBasicTagList = state!.originalBasicTagList;
                }

                const groups = yield call(datatagGroupService.getList, { isValid: true, category: "customer", unitId: getCurrentUnitId() });

                let result = transformArrFromMultiToSingle(groups.map((x) => x.tagList));
                const basicTagList = result.filter((x) => originalBasicTagList.some((a) => a.id === x.id));
                result = result.filter((x) => !originalBasicTagList.some((a) => a.id === x.id));

                yield put({ type: "input", data: { basicTagList, tagList: result || [] } });
            } catch (e) {
                yield put({ type: "hideLoading" });
                yield call(message?.error, "" + e.errmsg);
            }
        },

        /**
         * 获取所有标签
         * @param { message }
         * @param { call, put }
         */
        *getAllTagListItems({ message }, { call, put, select }) {
            try {
                const state = yield select((state) => state[Namespaces.newpolicyCalculator]),
                    allTagListItems = state!.allTagListItems;

                if (allTagListItems) {
                    return;
                }

                const tagItemList = yield call(datatagService.getList);

                yield put({ type: "input", data: { allTagListItems: tagItemList || [] } });
            } catch (e) {
                yield put({ type: "hideLoading" });
                yield call(message?.error, "" + e.errmsg);
            }
        },

        /**
         * 处理政策标签
         * @param { message }
         * @param { call, put }
         */
        *handlePolicyTagList({ message, pageIndex }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });

                let state = yield select((state) => state[Namespaces.newpolicyCalculator]),
                    tagList = state!.tagList;
                if (tagList?.length) {
                    const showTagList = tagList.filter((_item, i) => i < DEFAULT_SHOW_NUMBER * pageIndex);
                    const isShowMoreButton = showTagList?.length < tagList?.length;
                    yield put({ type: "input", data: { showTagList, isShowMoreButton } });
                    yield put({ type: "getPolicyTagListItems", message, tagList: showTagList });
                } else {
                    yield put({ type: "hideLoading" });
                }
            } catch (e) {
                yield put({ type: "hideLoading" });
                yield call(message?.error, "" + e.errmsg);
            }
        },

        /**
         * 获取政策标签项
         * @param { message, tagList }
         * @param { call, put, select }
         */
        *getPolicyTagListItems({ message, tagList }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });
                let state = yield select((state) => state[Namespaces.newpolicyCalculator]),
                    allTagListItems = state!.allTagListItems || [];

                for (let i = 0; i < tagList?.length; i++) {
                    const tag = tagList[i];
                    if (tag?.dataTypeValue === PolicyTagDataTypeEnum.string) {
                        const tagId = tag?.id;
                        let tagItemList = state![tag?.id];
                        if (!tagItemList) {
                            tagItemList = allTagListItems.filter((x) => x.tagClassId === tagId && x.tagName && x.tagName.trim());
                        }
                        yield put({ type: "input", data: { [tagId]: tagItemList || [] } });
                    }
                }
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 处理选中的标签
         * @param { message, isNotAllowEmpty: 是否不允许为空, functionMap: 处理完数据后执行的方法, callback, isGenerateOperateLog: 是否做插入记录操作，默认为true }
         * @param { call, put, select }
         */
        *handleSelectedTagList({ message, callback, functionMap = "postMatchPredictResult", isNotAllowEmpty, isGenerateOperateLog }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });
                yield put({ type: "input", data: { isSubmit: true } });

                let state = yield select((state) => state[Namespaces.newpolicyCalculator]),
                    basicTagList = state!.basicTagList,
                    tagList = state!.tagList,
                    selectedTagObj = state!.selectedTagObj || {};

                let tagobj: any = {};

                // 处理空数据
                Object.keys(selectedTagObj).forEach((key) => {
                    const item = selectedTagObj[key];
                    if ((!Array.isArray(item) && item) || (Array.isArray(item) && item?.length > 0)) {
                        tagobj[key] = item;
                    }
                });

                if (isNotAllowEmpty && !Object.keys(tagobj)?.length) {
                    message?.error("至少选择一项标签");
                    yield put({ type: "input", data: { isSubmit: false } });
                    yield put({ type: "hideLoading" });
                    return;
                }

                const paramTagList: any = policyTagJsonToList([...basicTagList, ...tagList], tagobj);

                yield put({ type: "input", data: { paramTagList } });
                yield put({ type: functionMap, isGenerateOperateLog, tagParams: { tagJson: JSON.stringify(tagobj), tagList: paramTagList }, message, callback });
            } catch (e) {
                yield put({ type: "input", data: { isSubmit: false } });
                yield put({ type: "hideLoading" });
                yield call(message?.error, "" + e.errmsg);
            }
        },

        /**
         * 政策计算
         * @param { message }
         * @param { call, put, select }
         */
        *postMatchPredictResult({ message, tagParams, callback, isGenerateOperateLog }, { call, put, select }) {
            try {
                yield put({ type: "input", data: { isSubmit: true } });

                let state = yield select((state) => state[Namespaces.newpolicyCalculator]),
                    certifyDetail = state!.certifyDetail;

                const result = yield call(policyCalculatorService.policyMatchPredictResult, {
                    parkId: getCurrentParkId(),
                    source: "Web",
                    sourceValue: SourceEnum.web,
                    customerId: certifyDetail?.companyId,
                    customerName: certifyDetail.companyName,
                    mobile: certifyDetail?.mobile,
                    generateOperateLog: isGenerateOperateLog,
                    ...tagParams,
                });
                yield put({ type: "input", data: { customerCalculator: result } });

                if (callback) {
                    yield call(callback);
                }
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
                yield put({ type: "input", data: { isSubmit: false } });
            }
        },

        /**
         * 政策订阅
         * @param { message, callback }
         * @param { call, put }
         */
        *subscription({ message, tagParams, callback }, { call, put, select }) {
            try {
                yield put({ type: "input", data: { isSubmit: true } });
                const state = yield select((state) => state[Namespaces.newpolicyCalculator]),
                    user = state!.user,
                    subscription = state!.subscription || {};

                const tags = tagParams?.tagList?.filter((x) => x.dateTypeValue === PolicyTagDataTypeEnum.string) || [];

                const implementationSubscribeTags = distinct(
                    [...transformArrFromMultiToSingle(tags.map((x) => x.tagValue.split(","))), ...(subscription?.implementationSubscribeTags?.split(",") || [])],
                    []
                ).filter((x) => x);
                const implementationSubscribeTagIds = distinct(
                    [...transformArrFromMultiToSingle(tags.map((x) => x.tagItemId.split(","))), ...(subscription?.implementationSubscribeTagIds?.split(",") || [])],
                    []
                ).filter((x) => x);

                const data = {
                    accountId: user?.currentUser?.id,
                    policySubscribeTags: subscription?.policySubscribeTags,
                    policySubscribeTagIds: subscription?.policySubscribeTagIds,
                    implementationSubscribeTags: implementationSubscribeTags.join(","),
                    implementationSubscribeTagIds: implementationSubscribeTagIds.join(","),
                };
                yield call(policySubscribeService.post, data);
                if (callback) {
                    yield call(callback);
                }
            } catch (e) {
                yield call(message!.error, e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
                yield put({ type: "input", data: { isSubmit: false } });
            }
        },
    };
}
app.model(newpolicyCalculatorModel);
