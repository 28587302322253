import { transformAssetsUrl, resolveEnvConfigProp } from '@reco-m/core';

export function getAssetsHomeUrl(): string {
    return resolveEnvConfigProp('assetsHomeUrl') ?? window['ASSETS_HOME_URL'] ?? window.parent['ASSETS_HOME_URL'] ?? '//kjcxyc.cn/';
}

export function resolveAssetsHomeUrl(url: string): string {
    if (url?.charAt(0) === '~') {
        const assetsUrl = getAssetsHomeUrl();

        return assetsUrl === void 0 ? transformAssetsUrl(url)! : `${assetsUrl}${url.substring(2)}`;
    }

    return url;
}
