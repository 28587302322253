import React from "react";
import PropTypes from "prop-types";

import { isEqual } from "lodash";
import { Modal } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Keyboard, Navigation, Pagination, Zoom } from "swiper";

import { requireEnsureCss, getObjectProp, getAssetsUrl, htmlInjectDecode, htmlInjectEncode, transformAssetsUrl, PureComponent } from "@reco-m/core";

import { getAssetsHomeUrl } from "../util";

import { BiEditorTypeEnum } from "./editor-type.enum";
import { isRelativePath as ckeditorIsRelativePath } from "./ckeditor";
import { isRelativePath as ewebeditorIsRelativePath, appendChild } from "./ewebeditor";

SwiperCore.use([Navigation, Pagination, Zoom, Keyboard]);

const ASSETS_HOME_URL = getAssetsHomeUrl(),
    editorType: BiEditorTypeEnum = getObjectProp(client, "plugins.editor.editorType", BiEditorTypeEnum.ckeditor),
    assets = getObjectProp(client, "plugins.ckeditor.css", ASSETS_HOME_URL + "assets/js/ckeditor5/classic/25.0.0/ckedit5.min");

export namespace BiEditorView {
    export interface IProps extends PureComponent.IProps {
        html?: string;
        encode?: boolean;
        className?: string;
        component?: any;

        editorType?: BiEditorTypeEnum;
    }

    export interface IState extends PureComponent.IState {
        open: boolean;
        imgs: string[];
        index: number;
    }

    export class Component<P extends IProps = IProps, S extends IState = IState> extends PureComponent.Base<P, S> {
        static propTypes = {
            data: PropTypes.string,
            editorType: PropTypes.string,
        };

        static defaultProps = {
            classPrefix: "html-content ck-content",
            component: "div",
            encode: true,
            editorType,
        };

        protected baseUrl: string;
        protected contentRef = React.createRef<any>();

        state = { open: false, imgs: [], index: 0 } as unknown as S;

        constructor(props: P, context: any) {
            super(props, context);

            this.baseUrl = getAssetsUrl();

            if (props.editorType === BiEditorTypeEnum.ckeditor) {
                requireEnsureCss(assets);
            }

            document.addEventListener("keydown", this.exitKeydown, false);
        }

        exitKeydown = (e) => {
            if (e.keyCode === 27) {
                this.onCancel();
            }
        };

        componentDidMount() {
            this.renderRef();
        }

        componentDidUpdate(prevProps: Readonly<P>) {
            if (prevProps.html !== this.props.html) {
                this.renderRef();
            }
        }

        shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>): boolean {
            const { props, state } = this;

            return !isEqual(nextProps, props) || !isEqual(nextState, state);
        }

        protected getContent() {
            let { html = "", encode } = this.props;

            if (html && (ewebeditorIsRelativePath || ckeditorIsRelativePath)) {
                html = html.replace(/(<\w+[^>]*\s+\b(?:src|href|url)\b=['"]?)~\/([^>]+>)/gi, `$1${this.baseUrl}$2`);
            }

            return (encode ? htmlInjectEncode : htmlInjectDecode)(html)!;
        }

        protected renderRef = () => {
            const { current: container } = this.contentRef;

            this.resolveCKEditorMedia(container);

            if (container) {
                const { imgs } = this.state;

                imgs.clear();

                container.querySelectorAll("img").forEach((img, index) => {
                    const { src, style } = img;

                    if (src && (src.startsWith("./") || src.startsWith("~/"))) {
                        img.src = `${this.baseUrl},${src.substring(2)}`;
                    }

                    if (!getComputedStyle(img, null)["max-width"]) {
                        style.maxWidth = "100%";
                    }

                    imgs.push(img.src);

                    img.addEventListener("click", () => this.setState({ open: true, index }), false);
                });

                container.querySelectorAll("a").forEach((a) => {
                    const { href } = a;

                    if (href && (href.startsWith("./") || href.startsWith("~/"))) {
                        a.href = `${this.baseUrl}${href.substring(2)}`;
                    }
                });
            }
        };

        protected resolveCKEditorMedia(container: HTMLElement) {
            if (container && this.props.editorType === BiEditorTypeEnum.ckeditor) {
                container.querySelectorAll("oembed").forEach((oembed) => {
                    const url = oembed.getAttribute("url");

                    if (url) {
                        const { searchParams } = new URL(url, this.baseUrl || location.href),
                            parent = oembed.parentNode,
                            wrapper = document.createElement("div"),
                            [width, height] = [searchParams.get("width") ?? searchParams.get("w"), searchParams.get("height") ?? searchParams.get("h")];

                        wrapper.classList.add("ck-media__wrapper");

                        appendChild(
                            `<video ${width ? `width=${width}` : ""} ${height ? `height=${height}` : ""} controls autoplay loop><source src="${url}" type="video/mp4"></video>`,
                            wrapper
                        );

                        parent!.insertBefore(wrapper, oembed);
                        parent!.removeChild(oembed);
                    }
                });
            }
        }

        onCancel() {
            this.setState({ open: false });
        }

        render(): React.ReactNode {
            const { component: Component, className } = this.props as any,
                content = this.getContent();

            return (
                <>
                    <Component className={this.classnames(className, this.getClassSet())} dangerouslySetInnerHTML={{ __html: content }} ref={this.contentRef}></Component>
                    {this.state.open ? (
                        <Modal
                            title={
                                <div>
                                    预览 <span className="size-12 text-error">(温馨提示：键盘左右可切换图片)</span>
                                </div>
                            }
                            wrapClassName="upload-modal"
                            footer={null}
                            width={"100%"}
                            visible={this.state.open}
                            destroyOnClose={true}
                            onCancel={() => this.onCancel()}
                        >
                            <Swiper initialSlide={this.state.index} zoom={true} keyboard={true} navigation={true} pagination={true} slidesPerView="auto">
                                {this.state.imgs &&
                                    this.state.imgs.length > 0 &&
                                    this.state.imgs.map((e, i) => {
                                        return (
                                            <SwiperSlide key={i}>
                                                <div className="reco-upload-item">
                                                    <img src={transformAssetsUrl(e)} alt="" />
                                                </div>
                                            </SwiperSlide>
                                        );
                                    })}
                            </Swiper>
                        </Modal>
                    ) : null}
                </>
            );
        }

        componentWillUnmount() {
            document.removeEventListener("keydown", this.exitKeydown);
        }
    }
}
