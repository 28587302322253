import React from "react";
import { message } from "antd";
// import { UploadProps } from "antd/lib/upload";

import { BasePicture } from "@reco-m/core";

import { getPictureDefaultProps } from "./util";
import { UploadWrap } from "./upload.wrap";

export namespace Picture {
    export interface IProps extends BasePicture.IProps {
        customUpload?: boolean;
        isShowTip?: boolean;
        tipText?: string;
    }

    export interface IState extends BasePicture.IState {}

    export class Component<P extends IProps = IProps, S extends IState = IState> extends BasePicture.Component<P, S> {
        static defaultProps = getPictureDefaultProps();

        protected onShowErrorMessage(msg: string): void {
            message.error(msg);
        }

        render(): React.ReactNode {
            return (
                <UploadWrap.Component
                    accept={
                        {
                            title: "Images",
                            extensions: "gif,jpg,jpeg,bmp,png",
                            mimeTypes: "image/gif,image/jpg,image/jpeg,image/bmp,image/png",
                        } as any
                    }
                    {...this.props}
                    picture
                    isShowTip={this.props.isShowTip}
                    tipText={this.props.tipText}
                />
            );
        }
    }
}
